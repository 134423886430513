.preview-image {
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
}

.reupload-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
  background: #efefef;
  text-align: center;
  cursor: pointer;
}

.reupload-btn span {
  color: #858fa2;
  font-size: 14px;
}

.preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  margin: 0;
}

.preview-image .cloud-icon {
  font-size: 21px;
  margin-right: 3px;
}

.asset-form-sub-title {
  font-size: 20px;
  color: #151515;
  font-weight: 600;
  margin: 15px 0px 25px 0px;
}

.album-track-wrapper {
  margin-top: 45px;
  margin-bottom: 30px;
}

.album-track-wrapper .btn-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 5px;
}

.album-track-wrapper .btn-wrapper button {
  width: 38px;
  height: 38px;
  border-radius: 5px;
  color: white;
}

.album-track-wrapper .btn-wrapper button.remove {
  background: #ee6c6c;
}

.album-track-wrapper .btn-wrapper button.add {
  background: #72c075;
}

.pt-40 {
  padding-top: 40px;
}

.pb-30 {
  padding-bottom: 30px;
}
