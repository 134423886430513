.file-upload-wrapper {
}

.file-label {
  font-size: 16px;
  color: #4a5568;
  font-weight: 400;
  margin-bottom: 6px;
}

.file-upload-error-border {
  border: 1px dashed red !important;
}

.file-content {
  position: relative;
  width: 100%;
  height: 250px;
  border: 1px dashed #858fa2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
}

.file-content input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.image-icon {
  color: #ef1c51;
  font-size: 42px;
}

.file-text {
  font-size: 14px;
  color: #151515;
  margin-bottom: 15px;
}

.file-span {
  color: #ef1c51;
  font-size: 14px;
}

.file-content h5 {
  font-size: 14px;
  font-weight: 700;
  color: #151515;
}

.file-content h6 {
  color: #858fa2;
  font-size: 12px;
}
