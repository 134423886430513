.form-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
}

.form-input-wrapper .pl-60 {
  padding-left: 60px;
}

.form-input-wrapper .pl-80 {
  padding-left: 80px;
}

.form-input-label {
  color: #4a5568;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
}

.form-input-inner {
  position: relative;
}

.form-input-inner .dark-label {
  position: absolute;
  background-color: #4a5568;
  color: white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 100%;
  font-size: 12px;
  line-height: 3;
  width: 40px;
  text-align: center;
  vertical-align: middle;
}

.form-input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  padding: 12px 15px;
}

.form-input:invalid[focused="true"] {
  border: 1px solid rgb(200, 110, 110);
}

.form-input:invalid[focused="true"] ~ span {
  display: block;
}

.input-error {
  border: 2px solid rgb(200, 110, 110);
}

.input-error::placeholder {
  color: rgb(200, 110, 110);
}

.form-input-error {
  color: rgb(200, 110, 110);
  font-weight: 700;
  font-size: 13px;
}
