.form-textarea-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
}

.form-textarea-wrapper .pl-30 {
  padding-left: 60px;
}

.form-textarea-label {
  color: #4a5568;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
}

.form-textarea-inner {
  position: relative;
}

.form-textarea-inner .dark-label {
  position: absolute;
  background-color: #4a5568;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 11px 20px;
}

.form-textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  padding: 10px 15px;
}

.form-textarea:invalid[focused="true"] {
  border: 1px solid rgb(200, 110, 110);
}

.form-textarea:invalid[focused="true"] ~ span {
  display: block;
}
