.form-select-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
}

.form-select-label {
  color: #4a5568;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
}

.form-select {
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  padding: 10px 15px;
}

.form-select:invalid[focused="true"] {
  border: 1px solid rgb(200, 110, 110);
}

.form-select:invalid[focused="true"] ~ span {
  display: block;
}

.input-error {
  border: 2px solid rgb(200, 110, 110);
}

.input-error::placeholder {
  color: rgb(200, 110, 110);
}

.form-input-error {
  color: rgb(200, 110, 110);
  font-weight: 700;
  font-size: 13px;
}
